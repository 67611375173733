(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    peopleToolTip() {
      const people = document.querySelectorAll('.c-member');

      if( !people.length ) {
        return;
      }

      people.forEach( person => {
        $(person).find('.c-member__image').append('<svg class="c-icon c-icon--plus-circled"><use xlink:href="#c-icon--plus-circled"></use></svg>');
        tippy(person, {
          html: person.querySelector('.c-member__inner').cloneNode(true),
          animateFill: false,
          animation: 'shift-away',
          arrow: true,
          arrowTransform: 'scale(1.35)',
          distance: 2
        });
      });

    },

    grayscale() {
      const images = $('.c-footer__column--3 img');
      images.addClass('grayscale grayscale-fade').gray();
    },

    pageTitleHide() {
      const content = $('.c-page-content--has-sidebar'),
        title = $('.c-page__title');

      if( content.length && title.length ) {
        title.closest('.o-layout').hide();
      }
    },

    blockOverlap() {
      const block = $('.c-block__inner--overlap');

      if( !block.length ) {
        return;
      }

      $.each(block, (i, el) => {
        const self = $(el),
          height = self.outerHeight(),
          overlap = self.attr('data-block-overlap'),
          overlapValue = overlap || 50,
          padding = parseFloat(self.parent().css('padding-top'));

        let margin = $(window).width() < 1025 ? 0 : -(height * (overlapValue / 100)) - 10 - (2*padding);

        self.css({
          'margin-bottom': margin,
        });
      });
    },

    footerHasCopyright() {
      const footer = $('.c-footer');
      const copyright = footer.find('.c-footer__copyright');

      if (!copyright.length) {
        footer.addClass('c-footer--no-copyright')
      }
    },

    footerRemoveEmptyPs() {
      const footer = $('.c-footer');

      const ps = footer.find('p');

      if( ps ) {
        ps.each((i, el) => {
          const self = $(el);

          if (self.html().replace('/\s|&nbsp;/g', '').length === 0) {
            self.remove();
          }
        });
      }
    },

    slider() {
      const sliders = $('.c-block__slider.owl-carousel');

      $.each(sliders, (i, el) => {
        const slider = $(el);
        const overlay = slider.find('.c-block__slider-slide-overlay');

        slider.css('opacity', 0);
        overlay.css('opacity', 0);

        slider.find('.owl-item.active .c-block__slider-slide').on('lazyloaded', () => {
          const activeSlide = slider.find('.owl-item.active .c-block__slider-slide');
          this.sliderMobileSetBg(activeSlide);
          slider.addClass('c-block__slider--isinit').css('opacity', 1);
        });

        slider.on('refresh.owl.carousel', () => {
          if (!slider.hasClass('c-block__slider--isinit')) {
            const activeSlide = slider.find('.owl-item.active .c-block__slider-slide');
            this.sliderMobileSetBg(activeSlide);
            //slider.css('opacity', 1);
          }
        });

        slider.on('drag.owl.carousel translate.owl.carousel', () => {
          //slider.css('opacity', 0);
          overlay.css('opacity', 0);
        });

        slider.on('resize.owl.carousel translated.owl.carousel', () => {
          const activeSlide = slider.find('.owl-item.active .c-block__slider-slide');
          this.sliderMobileSetBg(activeSlide);
          //slider.css('opacity', 1);
        });
      });
    },

    sliderMobileSetBg(slide) {
      const windowWidth = $(window).width();
      const caption = slide.find('.c-block__slider-slide-caption-inner');
      const overlay = slide.find('.c-block__slider-slide-overlay');

      if (windowWidth < 769) {
        if (caption.length) {
          const captionHeight = caption.outerHeight();
          const bgHeight = 200;
          const bgImage = slide.css('background-image');

          if (bgImage != 'none') {
            if (!slide.find('.c-block__slider-slide--mobile-bg').length) {
              slide.prepend('<div class="c-block__slider-slide--mobile-bg"></div>');
              slide.find('.c-block__slider-slide--mobile-bg').css('background-image', bgImage);
              slide.attr('data-old-bg', bgImage);
            }
            slide.addClass('c-block__slider-slide--no-bg').css('background-image', 'none');
          }

          slide.css('height', captionHeight + bgHeight);
          overlay.css('height', captionHeight);
          slide.closest('.owl-stage-outer').css('height', captionHeight + bgHeight);
        }
      }else {
        overlay.css('height', '100%');
        slide.css('background-image', slide.attr('data-old-bg'));
      }

      overlay.stop().animate({opacity:1}, 100);
    },

    quotesSlider() {
      const sliders = $('.c-quotes').find('.owl-carousel');

      if (!sliders.length) {
        return;
      }

      $.each(sliders, (i, el) => {
        const slider = $(el);

        slider.on('initialized.owl.carousel', () => {
          slider.find('.c-quote').css({
            opacity: 1,
          });
        });

        slider.owlCarousel({
          nav: true,
          navText: ['', '<span class="c-btn c-btn--link">More Quotes</span>'],
          dots: false,
          navSpeed: 500,
          dotsSpeed: 500,
          center: false,
          autoplaySpeed: 500,
          loop: true,
          autoplay: false,
          autoplayTimeout: 500,
          autoplayHoverPause: true,
          animateIn: 'FadeIn',
          animateOut: 'fadeOut',
          //mouseDrag: false,
          items: 1
        });

        slider.on('change.owl.carousel', () => {
          slider.find('.c-quote').css({
            opacity: 0,
          });
        });

        slider.on('translated.owl.carousel', () => {
          slider.find('.c-quote').stop().animate({
            opacity: 1,
          }, 200);
        });
      });
    },

    ucbCoursesIframe() {
      const list = $('.c-ucb-courses__list');

      if (!list) {
        return;
      }

      const url = list.data('courses-url');

      if (!url || typeof url === 'undefined') {
        return;
      }

      const iframe = $('<iframe></iframe', {
        id: 'c-ucb-courses__iframe',
        title: 'Courses List',
        width: '100%',
        height: '100%',
        frameborder: '0',
        scrolling: 'yes',
        marginheight: '0',
        marginwidth: '0',
        src: url,
      });

      list.html(iframe);
    },

    init() {
      $(document).ready( () => {
        //this.peopleToolTip();
        //this.pageTitleHide();
        this.footerHasCopyright();
        this.footerRemoveEmptyPs();
        this.ucbCoursesIframe();
      });

      $(window).on('load', () => {
        //this.grayscale();
        this.blockOverlap();
        this.slider();
        this.quotesSlider();
      });

      $(window).on('resize', () => {
        this.blockOverlap();
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
